<template>
<div class="bg-purple-100">
    <h1 class="px-2 mt-4 text-xl font-sans font-semi-bold bg-purple-300 rounded-t-lg">Tentang Kami</h1>
    <div class="mt-4 px-4 py-4 text-center">
      <p><span class="text-lg p-2  font-medium text-extrabold text-black">Teknologi 89</span> merupakan solusi digital terjangkau,tercepat dan tentunya terpercaya sebagai mitra usaha anda, 
         baik dari perusahaan UMKM sampai sekelas perusahaan. Kami memiliki motto berikan pelayanan sepenuh hati terhadap semua mitra.</p>
    </div>


    <h1 class="px-2 mt-4 text-xl font-sans font-semi-bold bg-purple-300 rounded-t-lg">Kontak</h1>
    <div>
        <div class="p-5 ">
         <div class="text-sm uppercase text-black font-medium">Temukan Kami</div>
         <a class="relative mx-10 my-3 block" href="/#">
            <svg xmlns="http://www.w3.org/2000/svg" class="absolute -mx-8 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
            </svg>KAV NO 35 PERUMAHAN SARIWANGI CITYVIEW BANDUNG JAWA BARAT <span class="text-teal-600 text-xs p-1"></span>
        </a>
         <a class="relative mx-10 my-3 block" href="tel:+6282245012207">
            <svg xmlns="http://www.w3.org/2000/svg" class="absolute -mx-8 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
            </svg>082245012207 <span class="text-teal-600 text-xs p-1"></span>
        </a>
         <a class="relative mx-10 my-3 block" href="mailto:admin@tekno89.my.id">
            <svg xmlns="http://www.w3.org/2000/svg" class="absolute -mx-8 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>admin@tekno89.my.id <span class="text-teal-600 text-xs p-1"></span>
        </a>
      </div>
    </div>
</div>
</template>
<script>
  export default {
    el: '#post',
    name : 'FAQ',
    data(){
    return{
                web:'',
    }
  },
    components:{
  },
    mounted() {
  },
    computed: {
  },
    methods:{

    }
}
</script>
